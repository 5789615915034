<template>
  <div>
    <h5 class="mb-10 font-weight-bolder" v-translate>Activate alerts</h5>
    <b-alert show variant="warning">
      <div class="d-flex justify-content-start align-items-center">
        <span class="svg-icon svg-icon-6x mt-3 mr-6 svg-icon-white">
          <inline-svg src="media/svg/icons/Communication/Urgent-mail.svg" />
        </span>
        <div>
          <h5 v-translate>Alerts are powerfull tools to monitor your balance</h5>
          <p class="font-size-md mb-0" v-translate>
            By setting alerts, you will send emails to thirdparties when threshold is reached. Choose a pallet, set a threshold, and set one or multiple emails.
          </p>
        </div>
      </div>
    </b-alert>

    <!--begin: Alerts button -->
    <!-- <b-button
      block
      variant="outline-opalean-gray-medium"
      size="lg"
      class="btn-hover-warning font-weight-bold mt-15 mb-2 py-5"
      @click="addAlert(true)"
      :disabled="syncedPartnerForm.PalletsAlerts.filter((a) => a.PalletID === null).length > 0"
      ><i class="flaticon2-plus icon-md"></i> <translate>Add a global alert</translate></b-button
    > -->
    <!--end: Alerts button -->

    <!--begin: Alerts button -->
    <b-button
      block
      variant="outline-opalean-gray-medium"
      size="lg"
      class="btn-hover-warning font-weight-bold mt-2 mb-2 py-5"
      @click="addAlert()"
      :disabled="Pallets.filter((p) => !syncedPartnerForm.PalletsAlerts.map((a) => a.PalletID).includes(p.PalletID)).length === 0"
      ><i class="flaticon2-plus icon-md"></i> <translate>Add a pallet alert</translate
      ><span class="ml-2 label label-outline-info label-pill label-inline" v-translate>New</span></b-button
    >
    <!-- Currently disabled 
          :disabled="Pallets.filter((p) => !syncedPartnerForm.PalletsAlerts.map((a) => a.PalletID).includes(p.PalletID)).length === 0"
    -->
    <!--end: Alerts button -->

    <!--begin: Alerts form -->
    <div id="alerts-form">
      <!--begin: Alert form : iterative fields -->
      <!--begin: Alert item -->
      <b-card no-body class="alert-form --border-warning my-2 border-0 bg-transparent" v-for="(alert, index) in syncedPartnerForm.PalletsAlerts" :key="index">
        <b-card-header header-tag="header" class="p-0 border-0 bg-transparent" role="tab">
          <b-button
            block
            v-b-toggle="`alert-form-${index}`"
            variant="transparent"
            class="px-8 d-flex justify-content-between align-items-center bg-light-warning alert-collapse-button"
          >
            <span class="font-size-h6 font-weight-bolder text-warning">
              <span class="svg-icon svg-icon-2x mr-2 svg-icon-warning">
                <inline-svg src="media/svg/icons/Electric/Highvoltage.svg" />
              </span>
              <span v-if="syncedPartnerForm.PalletsAlerts[index].action !== 'create' && syncedPartnerForm.action === 'edit'" class="svg-icon svg-icon-2x mr-2">
                <inline-svg src="media/svg/icons/General/Lock.svg" />
              </span>
              <translate>Alert</translate> #{{ index + 1 }}</span
            >
            <a class="btn btn-outline-warning btn-hover-warning p-2 px-3" @click="removeAlert(index)"> <i class="la la-remove p-0"></i></a
          ></b-button>
        </b-card-header>
        <b-collapse visible :id="`alert-form-${index}`" :accordion="`alert-form-${index}`" role="tabpanel">
          <b-card-body class="border rounded-bottom-left rounded-bottom-right border-top-0">
            <b-row>
              <b-col md="6">
                <div class="row">
                  <div class="form-group col-12 mb-0" v-if="syncedPartnerForm.PalletsAlerts[index].PalletID !== null">
                    <label class="font-weight-bold" :for="`alert-item-pallet-${index}`"
                      ><translate>Pallet</translate>
                      <span
                        v-if="syncedPartnerForm.PalletsAlerts[index].action !== 'create' && syncedPartnerForm.action === 'edit'"
                        class="svg-icon svg-icon-1x ml-2 svg-icon-opalean-gray-medium"
                      >
                        <inline-svg src="media/svg/icons/General/Lock.svg" /></span
                      ><span v-else class="required">*</span></label
                    >
                    <h5 class="my-2" v-if="syncedPartnerForm.PalletsAlerts[index].action !== 'create' && syncedPartnerForm.action === 'edit'">
                      <span
                        class="svg-icon svg-icon-md mr-3"
                        :class="getPalletType(getPalletValue(syncedPartnerForm.PalletsAlerts[index].PalletID, 'PalletType'), 'class')"
                      >
                        <inline-svg :src="getPalletType(getPalletValue(syncedPartnerForm.PalletsAlerts[index].PalletID, 'PalletType'), 'icon')"></inline-svg
                      ></span>
                      <a class="font-weight-bolder text-dark --text-hover-primary">{{ syncedPartnerForm.PalletsAlerts[index].Pallet.PalletName }}</a>
                    </h5>
                    <Multiselect
                      v-else
                      :id="`alert-item-pallet-${index}`"
                      v-model="syncedPartnerForm.PalletsAlerts[index].Pallet"
                      :options="Pallets.filter((p) => !syncedPartnerForm.PalletsAlerts.map((a) => a.PalletID).includes(p.PalletID))"
                      label="PalletName"
                      track-by="PalletID"
                      :placeholder="$gettext('Select a pallet...')"
                      @select="
                        (selectedOption, id) => {
                          $emit('update', {
                            PalletID: selectedOption.PalletID,
                            PalletsAlertsIndex: index,
                          });
                        }
                      "
                      @remove="
                        (removedOption, id) => {
                          $emit('update', {
                            PalletID: '',
                            PalletsAlertsIndex: index,
                          });
                        }
                      "
                    ></Multiselect
                    ><!--@select="syncAlert()"-->
                    <b-form-text :id="`alert-item-debtType-${index}-help`" v-translate>Choose the pallet to trigger.</b-form-text>
                  </div>
                </div>

                <div class="row">
                  <!-- DebtType -->
                  <div class="form-group col-6 mb-0">
                    <label class="font-weight-bold" :for="`alert-item-debtType-${index}`">
                      <translate>Debt type</translate>
                      <span
                        v-if="syncedPartnerForm.PalletsAlerts[index].action !== 'create' && syncedPartnerForm.action === 'edit'"
                        class="svg-icon svg-icon-1x ml-2 svg-icon-opalean-gray-medium"
                      >
                        <inline-svg src="media/svg/icons/General/Lock.svg" /></span
                      ><span v-else class="required">*</span></label
                    >
                    <b-form-radio-group
                      buttons
                      button-variant="outline-opalean-gray-light font-weight-bold"
                      :id="`alert-item-debtType-${index}`"
                      :name="`alert-item-debtType-${index}`"
                      v-model="syncedPartnerForm.PalletsAlerts[index].Direction"
                      :options="debtTypes"
                      value-field="id"
                      text-field="name"
                      size="lg"
                      required
                      :disabled="syncedPartnerForm.PalletsAlerts[index].action !== 'create' && syncedPartnerForm.action === 'edit'"
                      class="font-size-h4 font-weight-bold text-dark-75 debtTypes"
                    >
                    </b-form-radio-group>
                    <b-form-text :id="`alert-item-debtType-${index}-help`" v-translate>Define if alert is triggered for a debt or a credit.</b-form-text>
                  </div>

                  <!-- Threshold -->
                  <div class="form-group col-6 mb-0">
                    <label class="font-weight-bold" :for="`alert-item-threshold-${index}`"
                      ><translate>Threshold</translate>
                      <span
                        v-if="syncedPartnerForm.PalletsAlerts[index].action !== 'create' && syncedPartnerForm.action === 'edit'"
                        class="svg-icon svg-icon-1x ml-2 svg-icon-opalean-gray-medium"
                      >
                        <inline-svg src="media/svg/icons/General/Lock.svg" /></span
                      ><span v-else class="required">*</span></label
                    >
                    <b-input-group size="lg" :prepend="syncedPartnerForm.PalletsAlerts[index].Direction === 'IN' ? '+' : '-'">
                      <b-form-input
                        :id="`alert-item-threshold-${index}`"
                        type="text"
                        :placeholder="$gettext('0')"
                        required
                        size="lg"
                        class="font-size-h4 font-weight-bold"
                        v-model="syncedPartnerForm.PalletsAlerts[index].MaxQuantity"
                        :disabled="syncedPartnerForm.PalletsAlerts[index].action !== 'create' && syncedPartnerForm.action === 'edit'"
                        v-mask="{ mask: '9', repeat: 5, greedy: false }"
                      >
                      </b-form-input>
                    </b-input-group>
                    <b-form-text :id="`alert-item-threshold-${index}-help`" v-translate>Fill the maximum value that will trigger an alert.</b-form-text>
                  </div>
                </div>
              </b-col>
              <b-col md="6">
                <div class="form-group">
                  <label class="font-weight-bold" :for="`alert-item-emails-${index}`"><translate>Email(s)</translate> <span class="required">*</span></label>
                  <Multiselect
                    :id="`alert-item-emails-${index}`"
                    v-model="syncedPartnerForm.PalletsAlerts[index].AlertEMails"
                    tag-placeholder="Add this as new email"
                    placeholder="Search or add an email"
                    :options="accountEmails"
                    :multiple="true"
                    :taggable="true"
                    @tag="(searchQuery, id) => addEmail(searchQuery, index)"
                  ></Multiselect>
                  <b-form-text :id="`alert-item-emails-${index}-help`" v-translate
                    >This list of emails will receive your defined alert. Fill one or multiple emails.</b-form-text
                  >
                </div>
              </b-col>
            </b-row>
          </b-card-body>
        </b-collapse>
      </b-card>
      <!--end: Alert item -->

      <!--end: Alert form : iterative fields -->
    </div>
    <!--end: Alerts form -->

    <!-- DEV -->
    <b-card class="mt-10" body-class="p-3" v-if="showDataDebug">
      <pre>syncedPartnerForm.PalletsAlerts: {{ syncedPartnerForm.PalletsAlerts }}</pre>
    </b-card>
  </div>
</template>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style scoped lang="scss">
.alert-collapse-button.not-collapsed {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
</style>

<script>
import statics from "@/core/statics/statics.js";
import helpers from "@/core/statics/helpers.js";
import { mapGetters } from "vuex";

import Multiselect from "vue-multiselect";

//import Swal from "sweetalert2";

export default {
  name: "wizard-partnerAlert",
  components: {
    Multiselect,
  },
  props: {
    syncedPartnerForm: Object,
  },
  data() {
    return {
      //Preferences
      showDataDebug: statics.showDataDebug,

      // Statics
      debtTypes: statics.debtTypes,

      // User emails
      accountEmails: [],
    };
  },
  computed: {
    ...mapGetters(["getDefaultPallets", "getPallet", "getUserEmails"]),
    //Get Pallets
    Pallets: function () {
      /* Returns only ByDefault === true Pallets in order */
      //return this.getPallets;
      return (
        _.orderBy(
          this.getDefaultPallets.filter((p) => p.PalletType === "X"), // Then, filter only X returnable pallets
          "DisplayOrder"
        ) ?? []
      );
    },
  },
  mixins: [helpers],
  methods: {
    // TODO
    getPalletID({ PalletID, PalletName }) {
      return `${PalletID} — [${PalletName}]`;
    },
    getPalletValue(id, value) {
      console.log("getPalletValue::", id, value);
      let _Pallet = [];
      if (typeof id !== "undefined" && typeof value !== "undefined") _Pallet = this.getPallet(id);
      if (typeof _Pallet !== "undefined") return _Pallet[value];
      else return [];
    },
    addAlert(global = false) {
      // Prepare object
      const newAlert = {
        PalletID: global ? null : undefined,
        Pallet: { PalletID: global ? null : undefined },
        Direction: "OUT",
        AlertEMails: [],
        action: "create",
      };

      // If this is a global alert, you cannot have more than 1 alert ( this alert should not be triggered because a disabled is added on button > 0 )
      if (global && this.syncedPartnerForm.PalletsAlerts.filter((a) => a.PalletID === null).length > 0)
        window.swalAlert.fire({
          title: vm.$gettext("You already defined a global alert"),
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          timer: 1200,
          //heightAuto: false,
        });
      else if (global && this.syncedPartnerForm.PalletsAlerts.filter((a) => a.PalletID === null).length === 0)
        // If global and first alert
        this.syncedPartnerForm.PalletsAlerts.push(newAlert);
      // If not a global
      else this.syncedPartnerForm.PalletsAlerts.push(newAlert);
    },
    // syncAlert() {
    //   // #TODO Verify datas
    //   // ...
    //   // Then, emit update
    //   this.$emit("update", {
    //     PalletsAlerts: this.syncedPartnerForm.PalletsAlerts,
    //   });
    // },
    removeAlert(index) {
      this.syncedPartnerForm.PalletsAlerts.splice(index, 1);
    },
    addEmail(newEmail, index) {
      console.log("addEmail::", newEmail, index);
      this.accountEmails.push(newEmail);
      this.syncedPartnerForm.PalletsAlerts[index].AlertEMails.push(newEmail);
    },
  },
  watch: {
    "syncedPartnerForm.PalletsAlerts": {
      immediate: false, // Initiate at first load, trigger the callback immediately with the current value of the expression
      deep: true, // Look deeper in object
      handler: function () {
        // No old object when it's an object
        // Note: `newValue` will be equal to `oldValue` here
        // on nested mutations as long as the object itself
        // hasn't been replaced.
        console.log(
          "emails Watch::syncedPartnerForm.PalletsAlerts::",
          this.syncedPartnerForm.PalletsAlerts,
          this.$mainFunctions.getFilteredObject(this.syncedPartnerForm.PalletsAlerts, ["AlertEMails"]),
          _.uniq(
            this.$mainFunctions
              .getFilteredObject(this.syncedPartnerForm.PalletsAlerts, ["AlertEMails"])
              .map((a) => a.AlertEMails)
              .reduce((k, v) => k.concat(v), [])
          )
        );

        // Mount emails
        if (this.syncedPartnerForm.PalletsAlerts.length > 0 && this.accountEmails.length === 0) {
          // Mount user emails if existing
          this.accountEmails = this.getUserEmails ?? [];
          // Then mount alerts emails if existing
          // Arrays in array : .reduce((k, v) => k.concat(v), [])
          // Objects in array : .map(e => ({name: e.key.name, active: e.active}))
          let AlertEMails = this.$mainFunctions
            .getFilteredObject(this.syncedPartnerForm.PalletsAlerts, ["AlertEMails"])
            .map((a) => a.AlertEMails)
            .reduce((k, v) => k.concat(v), []);
          // Concat arrays of unique emails if duplicates
          this.accountEmails = this.accountEmails.concat(_.uniq(AlertEMails));
        }
      },
    },
  },
};
</script>
