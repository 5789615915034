<template>
  <!-- begin::EditPartner -->
  <div>
    <b-card class="mt-3 gutter-b" v-if="showDataDebug">
      <pre class="m-0"><b>action:</b> {{ syncedPartner.action }}</pre>
      <pre class="m-0">prevPartnerID: {{ prevPartnerID }}</pre>
      <pre class="m-0">nextPartnerID: {{ nextPartnerID }}</pre>
      <pre class="m-0">lat:{{ syncedPartner.PartnerDetails.Latitude }} {{ typeof syncedPartner.PartnerDetails.Latitude }}</pre>
      <pre class="m-0">lng:{{ syncedPartner.PartnerDetails.Longitude }} {{ typeof syncedPartner.PartnerDetails.Longitude }}</pre>
    </b-card>

    <div class="row" v-if="showDataDebug">
      <div class="col-6">
        <div class="card card-custom card-stretch --gutter-b card-scroll p-4">
          <pre class="mb-0"><b>hashedInitialSyncedPartner (sha1) :</b> {{ hashedInitialSyncedPartner }}</pre>
        </div>
      </div>
      <div class="col-6">
        <div class="card card-custom card-stretch --gutter-b card-scroll p-4">
          <pre
            class="mb-0"
          ><b>hashedSyncedPartner (sha1) :</b> <span :class="{'text-info' : hashedInitialSyncedPartner != hashedSyncedPartner}">{{ hashedSyncedPartner }}</span></pre>
        </div>
      </div>
      <div class="col-6">
        <div class="card card-custom card-stretch --gutter-b card-scroll h-250px p-4">
          <pre class="mb-0"><b>initialSyncedPartner :</b> {{ initialSyncedPartner }}</pre>
        </div>
      </div>
      <div class="col-6">
        <div class="card card-custom card-stretch --gutter-b card-scroll h-250px p-4">
          <pre
            class="mb-0"
          ><b>modifiedPartner :</b> <span :class="{'text-info' : hashedInitialSyncedPartner != hashedSyncedPartner}">{{ modifiedPartner }}</span></pre>
        </div>
      </div>
    </div>

    <div class="card card-custom gutter-b mb-10">
      <!-- begin::Header -->
      <div
        class="card-header p-0 h-200-px"
        v-if="
          typeof syncedPartner.PartnerDetails.Latitude === 'number' &&
          syncedPartner.PartnerDetails.Latitude !== 0 &&
          typeof syncedPartner.PartnerDetails.Longitude === 'number' &&
          syncedPartner.PartnerDetails.Longitude !== 0 &&
          getUserCapability('CanGeoloc')
        "
      >
        <GoogleMap
          v-bind:elclass="'w-100 h-200px rounded-sm rounded-bottom-0'"
          v-bind:position="{ lat: syncedPartner.PartnerDetails.Latitude, lng: syncedPartner.PartnerDetails.Longitude }"
        />
      </div>
      <!-- end::Header -->

      <div class="card-body">
        <!--begin::Toolbar -->
        <div class="card-toolbar d-flex justify-content-end pt-2 position-absolute right-0">
          <a
            v-if="syncedPartner.action === 'edit' && prevPartnerID !== undefined"
            class="btn btn-transparent btn-hover-opalean-gray-medium text-hover-white p-2 mr-2"
            v-b-tooltip.hover.bottom="$gettext('Navigate to previous partner')"
            @click="previousPartner()"
            ><span class="svg-icon svg-icon-opalean-gray-medium m-0 handle"><inline-svg src="media/svg/icons/Navigation/Arrow-left.svg"></inline-svg> </span>
            <span class="visually-hidden-focusable sr-only" v-translate>Previous partner</span>
          </a>
          <a
            v-if="syncedPartner.action === 'edit' && nextPartnerID !== undefined"
            class="btn btn-transparent btn-hover-opalean-gray-medium text-hover-white p-2 mr-2"
            v-b-tooltip.hover.bottom="$gettext('Navigate to next partner')"
            @click="nextPartner()"
            ><span class="svg-icon svg-icon-opalean-gray-medium m-0 handle"><inline-svg src="media/svg/icons/Navigation/Arrow-right.svg"></inline-svg> </span>
            <span class="visually-hidden-focusable sr-only" v-translate>Next partner</span>
          </a>
          <!-- <a href="#" class="btn btn-transparent btn-hover-opalean-gray-medium text-hover-white p-2"
          ><span class="svg-icon svg-icon-opalean-gray-medium m-0 handle"><inline-svg src="media/svg/icons/General/Other1.svg"></inline-svg></span>
        </a> -->
        </div>
        <!--end::Toolbar -->

        <!--begin::Top-->
        <div class="d-flex">
          <!--begin::Symbol-->
          <div class="flex-shrink-0 mr-7">
            <div
              class="symbol symbol-50 symbol-lg-120"
              :class="[
                syncedPartner.Partner.Name != '' && syncedPartner.Partner.Name !== undefined && syncedPartner.Partner.MainRole !== undefined
                  ? `symbol-light-${getMainRoleType(syncedPartner.Partner.MainRole, 'class')}`
                  : 'symbol-unset',
                syncedPartner.Partner.Name != '' && syncedPartner.Partner.Name !== undefined && syncedPartner.Partner.MainRole !== undefined
                  ? null
                  : 'symbol-unset',
                typeof syncedPartner.Partner.Attributes !== 'undefined' &&
                !syncedPartner.Partner.Attributes.includes('isActive') &&
                syncedPartner.Partner.Name !== undefined &&
                syncedPartner.Partner.MainRole !== undefined
                  ? 'symbol-muted'
                  : null,
              ]"
            >
              <!-- Unactive     : symbol-unactive -->
              <!-- Unset        : symbol-unset -->
              <span class="font-size-h1 symbol-label font-weight-boldest">{{ getPartnerSymbol(syncedPartner.Partner.Name) }}</span>
            </div>
          </div>
          <!--end::Symbol-->
          <!--begin: Info-->
          <div class="flex-grow-1">
            <!--begin::Title-->
            <div class="d-flex align-items-center justify-content-between flex-wrap mt-2">
              <!--begin::User-->
              <div class="mr-3">
                <!--begin::Name-->
                <a
                  href="#"
                  class="d-flex align-items-center text-hover-primary font-size-h5 font-weight-bold mr-3"
                  :class="syncedPartner.Partner.Name != '' && syncedPartner.Partner.Name !== undefined ? 'text-dark' : 'text-muted'"
                  >{{ syncedPartner.Partner.Name != "" && syncedPartner.Partner.Name !== undefined ? syncedPartner.Partner.Name : "Company name" }}
                  <i class="flaticon2-correct text-success icon-md ml-2"></i
                ></a>
                <!--end::Name-->
                <!--begin::Contacts-->
                <div class="d-flex flex-wrap my-2 mb-lg-0 mb-2">
                  <a
                    :href="`mailto:${syncedPartner.PartnerDetails.EMail}`"
                    class="text-muted text-hover-primary font-weight-bolder mr-lg-8 mr-4"
                    v-if="syncedPartner.PartnerDetails.EMail !== undefined"
                  >
                    <span class="svg-icon svg-icon-md svg-icon-gray-500 mr-1">
                      <inline-svg src="media/svg/icons/Communication/Mail.svg"></inline-svg>
                    </span>
                    {{ syncedPartner.PartnerDetails.EMail | safePrint }}</a
                  >

                  <span
                    class="text-muted text-hover-primary font-weight-bold mr-lg-8 mr-4"
                    v-if="syncedPartner.PartnerDetails.Phone !== undefined && syncedPartner.PartnerDetails.Phone != ''"
                  >
                    <span class="svg-icon svg-icon-md svg-icon-gray-500 mr-1"> <inline-svg src="media/svg/icons/Communication/Call.svg"></inline-svg> </span
                    ><translate>Phone</translate> : {{ syncedPartner.PartnerDetails.Phone | safePrint }}</span
                  >

                  <span
                    class="text-muted text-hover-primary font-weight-bold mr-lg-8 mr-4"
                    v-if="syncedPartner.PartnerDetails.Fax !== undefined && syncedPartner.PartnerDetails.Fax != ''"
                  >
                    <span class="svg-icon svg-icon-md svg-icon-gray-500 mr-1"> <inline-svg src="media/svg/icons/Communication/Archive.svg"></inline-svg> </span
                    ><translate>Fax</translate> : {{ syncedPartner.PartnerDetails.Fax | safePrint }}</span
                  >

                  <span
                    class="text-muted text-hover-primary font-weight-bold mr-lg-8 mr-4"
                    v-if="syncedPartner.PartnerDetails.Reference !== undefined && syncedPartner.PartnerDetails.Reference != ''"
                  >
                    <span class="svg-icon svg-icon-md svg-icon-gray-500 mr-1">
                      <inline-svg src="media/svg/icons/Communication/Dial-numbers.svg"></inline-svg> </span
                    ><translate>Reference</translate> : {{ syncedPartner.PartnerDetails.Reference | safePrint }}</span
                  >
                </div>
                <!--end::Contacts-->
              </div>
              <!--begin::User-->
              <!--begin::Actions-->
              <div class="my-lg-0 my-1">
                <span
                  v-if="syncedPartner.Partner.Active !== undefined"
                  class="label label-inline label-lg label-primary font-weight-bold p-3"
                  :class="syncedPartner.Partner.Active === true ? 'label-primary' : 'label-danger'"
                >
                  <span v-if="syncedPartner.Partner.Active === true" v-translate>Active</span>
                  <span v-else v-translate>Unactive</span>
                </span>
              </div>
              <!--end::Actions-->
            </div>
            <!--end::Title-->
            <!--begin::Content-->
            <div class="d-flex align-items-center flex-wrap justify-content-between">
              <!--begin::Description-->
              <div class="flex-grow-1 font-weight-medium text-dark-50 py-2 py-lg-2 mr-5">
                <span v-html="computedAddress()"></span>
              </div>
              <!--end::Description-->
            </div>
            <!--end::Content-->
          </div>
          <!--end::Info-->
        </div>
        <!--end::Top-->
        <!--begin::Separator-->
        <div class="separator separator-solid my-7"></div>
        <!--end::Separator-->
        <!--begin::Bottom-->
        <div class="d-flex align-items-center flex-wrap">
          <!--begin: Item-->
          <div class="d-flex align-items-center flex-lg-fill mr-5 my-1">
            <span class="svg-icon svg-icon-3x svg-icon-opalean-gray-medium mr-4">
              <!--begin::Svg Icon -->
              <!-- Pour victor > rendre dynamique avec le partnerMainRoleTypes.id retenu ( create ) et / ou avec le role quand cela vient d'un edit -->
              <inline-svg
                :src="
                  syncedPartner.Partner.MainRole !== undefined
                    ? getMainRoleType(syncedPartner.Partner.MainRole, 'icon')
                    : 'media/svg/icons/Navigation/minus.svg'
                "
              ></inline-svg>
              <!--end::Svg Icon-->
            </span>

            <div class="d-flex flex-column text-dark-75">
              <span class="font-weight-bolder font-size-sm">Role</span>
              <span class="text-dark-50 font-weight-bolder font-size-h5">
                <!-- — = class:text-dark-50-->
                {{ syncedPartner.Partner.MainRole !== undefined ? getMainRoleType(syncedPartner.Partner.MainRole, "name") : "—" }}</span
              >
            </div>
          </div>
          <!--end: Item-->
          <!--begin: Item-->
          <div class="d-flex align-items-center flex-lg-fill mr-5 my-1">
            <span class="svg-icon svg-icon-3x svg-icon-opalean-gray-medium mr-4">
              <!--begin::Svg Icon -->
              <inline-svg src="media/svg/icons/Communication/Address-card.svg"> </inline-svg>
              <!--end::Svg Icon-->
            </span>

            <div class="d-flex flex-column text-dark-75">
              <span class="font-weight-bolder font-size-sm">Group A</span>
              <span class="text-dark-50 font-weight-bolder font-size-h5">
                <!-- — = class:text-dark-50-->
                {{ syncedPartner.PartnerDetails.GroupA | safePrint }}</span
              >
            </div>
          </div>
          <!--end: Item-->
          <!--begin: Item-->
          <div class="d-flex align-items-center flex-lg-fill mr-5 my-1">
            <span class="svg-icon svg-icon-3x svg-icon-opalean-gray-medium mr-4">
              <!--begin::Svg Icon -->
              <inline-svg src="media/svg/icons/Communication/Address-card.svg"> </inline-svg>
              <!--end::Svg Icon-->
            </span>
            <div class="d-flex flex-column text-dark-75">
              <span class="font-weight-bolder font-size-sm">Group B</span>
              <span class="text-dark-50 font-weight-bolder font-size-h5">
                <!-- — = class:text-dark-50-->
                {{ syncedPartner.PartnerDetails.GroupB | safePrint }}</span
              >
            </div>
          </div>
          <!--end: Item-->
          <!--begin: Item-->
          <div class="d-flex align-items-center flex-lg-fill mr-5 my-1">
            <span class="svg-icon svg-icon-3x svg-icon-warning mr-4">
              <!--begin::Svg Icon -->
              <inline-svg src="media/svg/icons/Communication/Urgent-mail.svg"> </inline-svg>
              <!--end::Svg Icon-->
            </span>
            <div class="d-flex flex-column flex-lg-fill">
              <span class="text-warning font-weight-bolder font-size-sm" v-translate>Alerts</span>
              <span class="text-warning font-weight-bolder font-size-h5">
                {{ syncedPartner.PalletsAlerts !== undefined && syncedPartner.PalletsAlerts.length > 0 ? syncedPartner.PalletsAlerts.length : "—" }}
              </span>
            </div>
          </div>
          <!--end: Item-->
        </div>
        <!--end::Bottom-->
      </div>
    </div>

    <!-- DEV FIX ? this one works  -->
    <!-- <wizardPartnerContact :syncedPartnerForm="Partner"></wizardPartnerContact> -->
    <!-- DEV FIX ? this one works  -->

    <NeoOpatraceTabWizard
      v-bind:title="$gettext('Partner')"
      v-bind:tabs="[$gettext('Contact details'), $gettext('Identification'), $gettext('Alerts')]"
      v-bind:tabsDescription="[$gettext('Fill the contact details of a partner'), $gettext('Fill in professional informations'), $gettext('Activate alerts')]"
      v-bind:tabsTemplate="['wizard-partnerContact', 'wizard-partnerIdentification', 'wizard-partnerAlert']"
      v-bind:cardAdditionnalClasses="'card-stretch'"
      v-bind:formColumnsClasses="'col-xl-8 mt-15'"
      v-bind:showDescription="false"
      v-bind:showTitle="false"
      :syncedPartnerForm="syncedPartner"
    ></NeoOpatraceTabWizard>

    <!-- <keep-alive>
      <NeoOpatraceTabWizard
        v-bind:title="$gettext('Partner')"
        v-bind:tabs="[$gettext('Contact details'), $gettext('Identification'), $gettext('Alerts')]"
        v-bind:tabsDescription="[$gettext('Fill the contact details of a partner'), $gettext('Fill in professional informations'), $gettext('Activate alerts')]"
        v-bind:tabsTemplate="['wizard-partnerContact', 'wizard-partnerIdentification', 'wizard-partnerAlert']"
        v-bind:cardAdditionnalClasses="'card-stretch'"
        v-bind:formColumnsClasses="'col-xl-8 mt-15'"
        v-bind:showDescription="false"
        v-bind:showTitle="false"
        :syncedPartnerForm.sync="syncedPartner"
        key="syncedPartnerForm"
      ></NeoOpatraceTabWizard>
    </keep-alive> -->
    <!-- DEV FIX ? remove .sync ? > nothing change -->
  </div>
  <!-- end::EditPartner -->
</template>

<script>
import Vue from "vue";
var vm = new Vue();

import NeoOpatraceTabWizard from "@/view/pages/wizard/NeoOpatraceTabWizard.vue";
import GoogleMap from "@/view/content/neoopatrace/components/GoogleMap.vue";

import statics from "@/core/statics/statics.js";
import helpers from "@/core/statics/helpers.js";

//import router from "../../../router.js";

import { mapGetters } from "vuex";
import { HAS_PARTNER_DRAFT, RESET_PARTNER_DRAFT } from "@/core/services/store/neoopatrace/datas.module";

import ApiService from "@/core/services/api.service";

import hash from "object-hash";

//import wizardPartnerContact from "@/view/pages/wizard/wizardcontent/wizard-partnerContact.vue";

export default {
  name: "editPartner",
  components: {
    NeoOpatraceTabWizard,
    GoogleMap,
    //wizardPartnerContact
  },
  title() {
    return vm.$gettext("menu.partners.create");
  },
  watch: {
    // Debounce watcher
    // https://stackoverflow.com/questions/45178621/how-to-correctly-use-vue-js-watch-with-lodash-debounce
    syncedPartner: {
      immediate: false, // Initiate at first load, trigger the callback immediately with the current value of the expression
      deep: true, // Look deeper in object
      handler: function (n) {
        // No old object when it's an object
        // Note: `newValue` will be equal to `oldValue` here
        // on nested mutations as long as the object itself
        // hasn't been replaced.
        if (true === this.fullyLoaded) this.diffSyncedPartner(n);
        console.log("√ diff Watch::syncedPartner");
      },
    },
  },
  data: function () {
    return {
      PartnerID: undefined, // Params
      prevPartnerID: undefined,
      nextPartnerID: undefined,
      // Preferences
      showHelpDescriptions: statics.showHelpDescriptions,
      showDataDebug: statics.showDataDebug,
      // Statics
      //MainRoles: statics.partnerMainRoleTypes,
      // Form
      syncedPartner: {
        action: "create",
        Partner: {
          Attributes: ["isActive"],
          CountryISO: undefined, // Prevent non-reactive merge when creating
        },
        PartnerDetails: {},
        PalletsAlerts: [],
      },
      // Watcher
      initialSyncedPartner: {},
      modifiedPartner: {},
      fullyLoaded: false,
    };
  },
  created() {
    console.log("√ Created", this.$route.params.PartnerID, this.syncedPartner.action, this.fullyLoaded);

    // Passed params
    if (this.$route.params.PartnerID != null) this.PartnerID = this.$route.params.PartnerID;

    // Actions
    if (this.PartnerID !== undefined) {
      // Add state loading here

      // Reset loading state
      this.fullyLoaded = false;

      ApiService.get("/partner", "ID=" + this.PartnerID).then((response) => {
        // Old data filling
        // this.Partner = { ...response.data.Partner, ...response.data.PartnerDetails };
        // this.syncedPartner.PalletsAlerts = response.data.PalletsAlerts;
        // New data filling
        this.syncedPartner = response.data;
        // Process Pallets to get PalletName by PalletID
        this.getPalletNames();
        // Set first next/prev IDs
        this.prevPartnerID = this.getPreviousPartnerByPartnerID(this.syncedPartner.Partner.PartnerID) ?? undefined;
        this.nextPartnerID = this.getNextPartnerByPartnerID(this.syncedPartner.Partner.PartnerID) ?? undefined;

        setTimeout(() => {
          console.log("√ callData:: Finally, store initial object", this.PartnerID);

          // Switch to edit
          this.$set(this.syncedPartner, "action", "edit");

          // Finally, store initial object
          // https://stackoverflow.com/questions/54078421/how-to-clone-props-object-and-make-it-non-reactive
          //          this.initialSyncedPartner = Object.assign({}, this.syncedPartner); // Make non-reactive
          this.initialSyncedPartner = this.$mainFunctions.noReactiveCopy(this.syncedPartner); // Make non-reactive
          this.fullyLoaded = true;
        }, 1000);
      });
    }
  },
  computed: {
    ...mapGetters(["getPreviousPartnerByPartnerID", "getNextPartnerByPartnerID", "getPallet", "getCountries", "getUserCountry", "getUserCapability"]),
    CountryISO() {
      return this.getCountries;
    },
    // Hash
    hashedInitialSyncedPartner() {
      return hash(this.initialSyncedPartner);
    },
    hashedSyncedPartner() {
      return hash(this.syncedPartner);
    },
  },
  mixins: [helpers],
  methods: {
    // Watcher debounce
    // Used to make a deep diff of partner object
    diffSyncedPartner: _.debounce(function (n) {
      /*
      console.log(
        "debounced diff Watch::syncedPartner",
        "n",
        n,
        "initialSyncedPartner",
        this.initialSyncedPartner,
        this.$mainFunctions.diff(n, this.initialSyncedPartner)
      );*/
      // Store a non-reactive compared object
      let keepKeys = ["PartnerID", "PalletID"]; // "Attributes"
      this.modifiedPartner = Object.assign({}, this.$mainFunctions.diff(n, this.initialSyncedPartner, false, false, keepKeys)); // Dernier argument = keepIndexes
      console.log("√ syncedPartner > modifiedPartner::", this.modifiedPartner);

      // Store ( in store ) a boolean to know if there is an edited operation
      if (Object.keys(this.modifiedPartner).length !== 0)
        // If there is ≠ in operation
        this.$store.dispatch(HAS_PARTNER_DRAFT).then(() => {});
      // If there is no ≠ in operation
      else this.$store.dispatch(RESET_PARTNER_DRAFT).then(() => {});
    }, 300), // Reducing delay from 1000 to 300 to prevent quick send errors

    // Static helpers methods
    // Used when v-for and v-if needed : v-if="item.MainRole == role.id"
    // ** See helpers.js
    // Local methods
    computedAddress() {
      console.log(
        "computedAddress::",
        this.syncedPartner.Partner.CountryISO,
        Boolean(this.syncedPartner.Partner.CountryISO),
        typeof this.CountryISO.filter((c) => c.ISO2 == this.syncedPartner.Partner.CountryISO),
        this.CountryISO.filter((c) => c.ISO2 == this.syncedPartner.Partner.CountryISO).length > 0
      );
      var r =
        (Boolean(this.syncedPartner.PartnerDetails.Address1) ? this.syncedPartner.PartnerDetails.Address1 + ", " : "") +
        (Boolean(this.syncedPartner.PartnerDetails.Address2) ? this.syncedPartner.PartnerDetails.Address2 + ", " : "") +
        (Boolean(this.syncedPartner.PartnerDetails.Address1) || Boolean(this.syncedPartner.PartnerDetails.Address2) ? "<br/>" : "") +
        (Boolean(this.syncedPartner.Partner.ZipCode) ? this.syncedPartner.Partner.ZipCode + " " : "") +
        (Boolean(this.syncedPartner.Partner.City) ? this.syncedPartner.Partner.City + ", " : "") +
        (Boolean(this.syncedPartner.Partner.District) ? "( " + this.syncedPartner.Partner.District + " ) " : "") +
        //(Boolean(this.syncedPartner.Partner.CountryISO) ? this.syncedPartner.Partner.CountryISO + " " : "");
        // Fix issue avoid V3 freeze "?" for Country
        (Boolean(this.syncedPartner.Partner.CountryISO) &&
        this.syncedPartner.Partner.CountryISO != "?" &&
        this.syncedPartner.Partner.CountryISO != undefined &&
        typeof this.CountryISO.filter((c) => c.ISO2 == this.syncedPartner.Partner.CountryISO) !== "undefined" &&
        this.CountryISO.filter((c) => c.ISO2 == this.syncedPartner.Partner.CountryISO).length > 0
          ? this.CountryISO.filter((c) => c.ISO2 == this.syncedPartner.Partner.CountryISO)[0].Name + " "
          : "");
      return r;
    },
    getPalletNames() {
      console.log("getPalletNames::");
      if (typeof this.syncedPartner.PalletsAlerts !== "undefined" && this.syncedPartner.PalletsAlerts.length > 0)
        this.syncedPartner.PalletsAlerts.forEach((a, index) => {
          console.log("getPalletNames::", a, a.PalletID);
          if (a.PalletID != null) {
            let _Pallet = this.getPallet(a.PalletID);
            console.log("getPalletNames::", _Pallet);
            // NOT REACTIVE
            //this.syncedPartner.PalletsAlerts[index].Pallet = { PalletID: _Pallet.PalletID, PalletName: _Pallet.PalletName };

            // REACTIVE
            // Update v-model
            // Tableaux https://fr.vuejs.org/v2/guide/reactivity.html#Pour-les-tableaux
            Vue.set(this.syncedPartner.PalletsAlerts[index], "Pallet", {
              PalletID: _Pallet.PalletID,
              PalletName: _Pallet.PalletName,
            });
          }
        });
      else console.log("getPalletNames::No PalletsAlerts");
    },
    getPartnerSymbol(Name) {
      if (typeof Name !== "undefined" && Name != null && Name != "") return this.$mainFunctions.getFirstLetters(Name);
      else return "NC";
    },
    // Next & Prev functions
    previousPartner() {
      // this.prevPartnerID = this.getPreviousPartnerByPartnerID(this.syncedPartner.Partner.PartnerID);
      console.log("previousPartner::", this.syncedPartner.Partner.PartnerID, this.prevPartnerID);

      // Re-route solution
      if (this.prevPartnerID !== undefined)
        this.$router.push({
          name: "route.partners.edit",
          params: {
            PartnerID: this.prevPartnerID,
          },
          replace: true,
        });

      // Re-load solution
      // if (prevPartnerID !== undefined)
      // ApiService.get("/partner", "ID=" + prevPartnerID).then((response) => {
      //   // Old data filling
      //   // this.Partner = { ...response.data.Partner, ...response.data.PartnerDetails };
      //   // this.syncedPartner.PalletsAlerts = response.data.PalletsAlerts;
      //   // New data filling
      //   this.Partner = response.data.Partner;
      //   this.PartnerDetails = response.data.PartnerDetails;
      //   this.syncedPartner.PalletsAlerts = response.data.PalletsAlerts;
      //   // Process Pallets to get PalletName by PalletID
      //   this.getPalletNames();
      //   // Pass edit action
      //   this.syncedPartner.action = "edit";
      //   this.syncedPartner.prevPartnerID = prevPartnerID;
      // });
    },
    nextPartner() {
      // this.nextPartnerID = this.getNextPartnerByPartnerID(this.syncedPartner.Partner.PartnerID);
      console.log("nextPartner::", this.syncedPartner.Partner.PartnerID, this.nextPartnerID);

      // Re-route solution
      if (this.nextPartnerID !== undefined)
        this.$router.push({
          name: "route.partners.edit",
          params: {
            PartnerID: this.nextPartnerID,
          },
          replace: true,
        });

      // Re-load solution
      // if (prevPartnerID !== undefined)
      // ApiService.get("/partner", "ID=" + nextPartnerID).then((response) => {
      //   // Old data filling
      //   // this.Partner = { ...response.data.Partner, ...response.data.PartnerDetails };
      //   // this.syncedPartner.PalletsAlerts = response.data.PalletsAlerts;
      //   // New data filling
      //   this.Partner = response.data;
      //   // Process Pallets to get PalletName by PalletID
      //   this.getPalletNames();
      //   // Pass edit action
      //   this.syncedPartner.action = "edit";
      //   this.syncedPartner.nextPartnerID = nextPartnerID;
      // });
    },
  },
  mounted() {
    console.info("EditPartner.vue");
    // Default Pallets
    console.log("√ Mount::Default Partner", this.syncedPartner.action, this.fullyLoaded, "getUserCountry CountryISO :: ", this.getUserCountry);

    // Default create mainrole
    if (this.syncedPartner.action === "create")
      //REACTIVE
      this.$set(this.syncedPartner.Partner, "MainRole", "TR");

    // Default CountryISO of User
    if (this.syncedPartner.action === "create")
      //REACTIVE
      this.$set(this.syncedPartner.Partner, "CountryISO", this.getUserCountry);

    // Default object
    // https://stackoverflow.com/questions/54078421/how-to-clone-props-object-and-make-it-non-reactive
    if (this.syncedPartner.action === "create" || this.syncedPartner.action === undefined) {
      // this.initialSyncedPartner = Object.assign({}, this.syncedPartner); // Make non-reactive
      this.initialSyncedPartner = this.$mainFunctions.noReactiveCopy(this.syncedPartner); // Make non-reactive
      this.fullyLoaded = true;
    }
  },
};
</script>
