<template>
  <div class="w-100 position-relative">
    <GmapMap :center="center" :zoom="zoom" :class="elclass" :options="mapOptions">
      <GmapMarker :key="index" v-for="(m, index) in markers" :position="m.position" @click="center = m.position" />
    </GmapMap>

    <div class="position-absolute top-0 left-0 ml-5">
      <GmapAutocomplete @place_changed="setPlace" />
      <button @click="addMarker">Add</button>
    </div>
  </div>
</template>

<script>
import statics from "@/core/statics/statics.js";

export default {
  name: "GoogleMap",
  components: {},
  props: {
    // Form
    elclass: String,
    position: Object,
  },
  data() {
    return {
      center: { lat: 46.52863469527167, lng: 2.43896484375 },
      zoom: 6,
      currentPlace: null,
      markers: [],
      places: [],

      //Preferences
      showDataDebug: statics.showDataDebug,

      // Statics
      mapOptions: statics.mapOptions,
    };
  },
  mounted() {
    this.locateMap(this.position);
  },
  methods: {
    // GoogleMap : Add
    setPlace(place) {
      this.currentPlace = place;
    },
    addMarker() {
      if (this.currentPlace) {
        const marker = {
          lat: this.currentPlace.geometry.location.lat(),
          lng: this.currentPlace.geometry.location.lng(),
        };
        this.markers.push({ position: marker });
        this.places.push(this.currentPlace);
        this.center = marker;
        this.currentPlace = null;
      }
    },
    // GoogleMap : locate
    locateMap: function (position) {
      this.center = {
        lat: position.lat,
        lng: position.lng,
      };
      this.zoom = 13;
      this.markers.push({ position: position });
    },
  },
};
</script>
