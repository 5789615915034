<template>
  <div>
    <h5 class="mb-10 font-weight-bolder" v-translate>Contact informations</h5>

    <!-- SIRET -->
    <div class="row">
      <div class="form-group col-6 mb-0">
        <label class="font-weight-bold" for="partnerSiretNumber"><translate>Siret number</translate></label>
        <b-form-input
          id="partnerSiretNumber"
          v-model="syncedPartnerForm.PartnerDetails.SIRET"
          debounce="0"
          :placeholder="$gettext('Siret number')"
          size="lg"
          v-mask="'999-999-999-99999'"
        ></b-form-input>
      </div>

      <!-- VAT -->
      <div class="form-group col-6 mb-0">
        <label class="font-weight-bold" for="partnerVATNumber"><translate>VAT number</translate></label>
        <b-form-input
          id="partnerVATNumber"
          v-model="syncedPartnerForm.PartnerDetails.VAT"
          debounce="0"
          :placeholder="$gettext('VAT number')"
          size="lg"
          v-mask="'AA*9999999999999'"
        ></b-form-input>
      </div>
    </div>

    <!-- GLN -->
    <div class="row">
      <div class="form-group col-6 mb-0">
        <label class="font-weight-bold" for="partnerGLNNumber"><translate>GLN number</translate></label>
        <b-form-input
          id="partnerGLNNumber"
          v-model="syncedPartnerForm.PartnerDetails.GLN"
          debounce="0"
          :placeholder="$gettext('GLN number')"
          size="lg"
          v-mask="'9999999999999'"
        ></b-form-input>
      </div>

      <!-- Infos -->
      <div class="form-group col-6 mb-0">
        <label class="font-weight-bold" v-translate for="partnerInformation">Complementary Information</label>
        <b-form-input
          id="partnerInformation"
          v-model="syncedPartnerForm.PartnerDetails.Infos"
          debounce="0"
          :placeholder="$gettext('Complementary Information')"
          size="lg"
        ></b-form-input>
      </div>
    </div>

    <!-- Group A -->
    <div class="row">
      <div class="form-group col-6 mb-0">
        <label class="font-weight-bold" for="partnerGroupA"><translate>Group A</translate></label>
        <b-form-input
          id="partnerGroupA"
          v-model="syncedPartnerForm.PartnerDetails.GroupA"
          debounce="0"
          :placeholder="$gettext('Group A')"
          size="lg"
        ></b-form-input>
      </div>

      <!-- Group B -->
      <div class="form-group col-6 mb-0">
        <label class="font-weight-bold" for="partnerGroupB"><translate>Group B</translate></label>
        <b-form-input
          id="partnerGroupB"
          v-model="syncedPartnerForm.PartnerDetails.GroupB"
          debounce="0"
          :placeholder="$gettext('Group B')"
          size="lg"
        ></b-form-input>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "wizard-partnerIdentification",
  props: {
    syncedPartnerForm: Object,
  },
  data() {
    return {
      syncedPartnerFormShowed: true,
    };
  },
};
</script>
